import React from 'react';
import Panel from '../Panel/Panel';
import classes from './Highlights.module.css';
import LisbonJPEG from '../assets/Lisbon.jpeg';
import LisbonWEBP from '../assets/Lisbon.webp';
import LisbonJPEG600 from '../assets/Mobile/Lisbon.jpeg'
import LisbonWEBP600 from '../assets/Mobile/Lisbon.webp'


const Linking = () =>{
    return(
        <section
            className={[classes.Section, classes.Linking].join(' ')}
        >
            <div className={classes.ImageContainer}>
                <picture>
                    <source srcSet={`${LisbonWEBP600} 600w, ${LisbonWEBP}`} type={'image/webp'} />
                    <source srcSet={`${LisbonJPEG600} 600w, ${LisbonJPEG}`} type={'image/jpeg'} />
                    <img 
                        width={'1271px'} 
                        height={'673px'} 
                        src={LisbonJPEG} 
                        className={classes.Image} 
                        alt={'Bookmarks connected in threads'}
                        />
                </picture>
            </div>
            <Panel
                title={'Create A Thread'}
                panelClasses={[classes.Panel, classes.LinkingPanel]}
            >
                <p>Connect your bookmarks and keep ideas together. No more dumping links in a folder...create a thread of links to preserve your train of thought.</p>
            </Panel>
        </section>
    )
};

export default Linking;