import React from 'react';

import ErrorTemplate from '../ErrorTemplate';
import Broken from './assets/Broken.svg';

const ItemReset = () =>{
    return (
        <ErrorTemplate 
            src={Broken}
            message={[<span>Something went wrong. </span>,<span>Press Continue to refresh the app.</span>]}
            button={'Continue'}
            clicked={() => {
                window.location.reload();
            }}
        />);
};

export default ItemReset;