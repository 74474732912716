import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user: null,
    error: null,
    loading: false,
    redirect: '/',
    init: true
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                error: null,
                loading: true
            }

        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                user: action.user,
                error: null,
                loading: false,
                init: false
            }

        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                user: null,
                error: action.error,
                loading: false,
                init: false
            }

        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                user: null,
            }

        case actionTypes.SET_AUTH_REDIRECT:
            return {
                ...state,
                redirect: action.redirect
            }
    
        default:
            return state;
    }
}

export default reducer;