import React from 'react';

import classes from './Button.module.css';

const ButtonDiv = (props) => {

    const styles = props.classes ? [classes.Button, classes[props.btnType], ...props.classes] : [classes.Button, classes[props.btnType]];

    return (
        <div
            disabled={props.disabled}
            className={styles.join(' ')}>
            {props.children}
        </div>
    )
};

export default ButtonDiv;