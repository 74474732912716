import React from 'react';

import classes from '../modal.module.css'

const Background = (props) =>{

    const backgroundClasses = props.classes? [classes.Background, ...props.classes] : [classes.Background];

    let background = null;

    if (props.visible){
        background = (
            <div
                className={backgroundClasses.join(' ')}
                onClick={(e) => {
                    e.stopPropagation();
                    if (props.close) props.close();
                    else return;
                }}
            >
            </div>
        )
    }

    return background;
};

export default Background;