import React, { useEffect, useRef } from 'react';
import Footer from './Footer';
import NavBar from './NavBar/NavBar';
import classes from './HomeLayout.module.css';
import { ScrollTrigger } from "gsap/all";

const HomeLayout = (props) =>{

    const ref = useRef(null);

    useEffect(() => {
        let trigger = ScrollTrigger.create({
            id: 'navBar',
            trigger: ref.current,
            start: 'top -20%',
            end: 'top 0%',
            onEnter: () => {
                if (!ref.current) return;
                ref.current.classList.add(classes.Scrolled)
            },
            onEnterBack: () => {
                if (!ref.current) return;
                ref.current.classList.remove(classes.Scrolled)
            },
            invalidateOnRefresh: true
        })

        return () => {if (trigger) trigger.kill()}
    });
    

    return (
        <main
            className={classes.Main}
            ref={ref}
        >
            <NavBar
                isAuthenticated={props.isAuthenticated}
                user={props.user}
            />
            <section
                className={classes.Content}
            >
                {props.children}
            </section>
            <Footer />
        </main>
    );

};

export default HomeLayout;