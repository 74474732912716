import cloneDeep from 'lodash.clonedeep';
import * as actionTypes from '../actions/actionTypes'

const initialState = {
    items: [],
    reset: false
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_ITEM:
            let selectState = cloneDeep(state);

            if (selectState.items.includes(action.itemId)) {
                const itemRemoved = selectState.items.filter((id) => {
                    return id !== action.itemId;
                });

                selectState.items = itemRemoved;

            } else selectState.items.push(action.itemId);

            if (action.reset){
                selectState.reset = !state.reset;
            }

            return selectState;

        case actionTypes.SELECT_ALL:
            let selectAllState = cloneDeep(state);

            selectAllState.items.push(...action.itemIds);

            selectAllState.reset = !state.reset;

            return selectAllState;

        case actionTypes.CLEAR_SELECTED:
            let clearState = {};
            
            clearState.items = [];

            clearState.reset = !state.reset;

            return clearState;
    
        default:
            return state;
    }
}

export default reducer;