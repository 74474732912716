import * as actionTypes from '../actions/actionTypes';
import cloneDeep from 'lodash.clonedeep';
import { isEqual } from 'lodash';

const userSettingsInit = {
    ancestrySettings: {
        expanded: false,
        minimized: false,
        basic: false
    }
}

const flowViewInit = {
    activeParent: null,
    activeAncestors: null,
    roots: [],
    singleRootView: true,
    scrollView: {}
};

const detailViewInit = {
    active: false,
    activeItem: null,
    past: [],
    future: []
};

const glimpseViewInit = {
    active: false,
    item: null,
    url: null,
    file: null
};

const formStateInit = {
    active: false,
    item: null,
    parent: null
};

const modalStateInit = {
    contents: null,
    component: null,
    props: null
};

const selectViewInit = {
    active: false,
    filters: []
};

const searchViewInit = {
    active: false,
    items: []
}

const initialViewState = {
    currentView: 'flowView',
    loadingView: false,
    flowView: flowViewInit,
    detailView: detailViewInit,
    glimpseView: glimpseViewInit,
    selectView: selectViewInit,
    searchView: searchViewInit,
    trashView: false,
    formState: formStateInit,
    modal: modalStateInit,
    userSettings: userSettingsInit
}


const resetAllViews = (state) => {
    
    //Spread Syntax to create new object instead of shallow references
    state.flowView = {...flowViewInit};
    state.detailView = {...detailViewInit};
    state.glimpseView = {...glimpseViewInit};
    state.selectView = {...selectViewInit};
    state.searchView = {...searchViewInit};
    state.formState = {...formStateInit};
    state.modal = {...modalStateInit};
    state.trashView = false;

    return state;
}

const resetViews = (state) => {
    //Spread Syntax to create new object instead of shallow references
    state.detailView = { ...detailViewInit };
    state.glimpseView = { ...glimpseViewInit };
    state.formState = { ...formStateInit };
    state.modal = { ...modalStateInit };

    return state;
}

const reducer = (state = initialViewState, action) => {
    switch (action.type) {

        case actionTypes.LOADING_VIEW:
            const loadingState = cloneDeep(state);
            loadingState.loading = action.value;
            
            return loadingState;

        case actionTypes.INIT_VIEW_STATE:
            
            if (!action.viewState) return state;
            let initViewState = action.viewState;

            //Set Specific Views
            initViewState.formState = {...formStateInit};
            initViewState.detailView = {...detailViewInit};
            initViewState.glimpseView = {...glimpseViewInit};
            initViewState.modal = { ...modalStateInit };
            initViewState.loadingView = false;

            if (initViewState.flowView.activeParent){
                initViewState.flowView.scrollView = {
                    parent: initViewState.flowView.activeParent,
                    beforeRender: true
                };
            }
            
            return initViewState;

        case actionTypes.SET_MODAL:
            let modalState = cloneDeep(state);

            modalState.modal.contents = action.contents;

            return modalState;

        case actionTypes.ACTIVE_ANCESTRY:

            let activeAncestryState = cloneDeep(state);

            activeAncestryState.flowView.activeParent = action.parent;
            activeAncestryState.flowView.activeAncestors = action.ancestors;
            
            return activeAncestryState;

        case actionTypes.ANCESTRY_SETTINGS:

            let ancestryState = cloneDeep(state);

            ancestryState.userSettings.ancestrySettings = {
                ...ancestryState.userSettings.ancestrySettings,
                ...action.settings
            };

            return ancestryState;

        case actionTypes.SET_FLOW_VIEW_ROOT:

            if (isEqual(state.flowView.roots, action.roots)) {
                return state;
            }

            let rootState = resetAllViews(cloneDeep(state));

            rootState.currentView = 'flowView';

            rootState.flowView.roots = action.roots;

            return rootState;

        case actionTypes.SET_SCROLL_VIEW:

            let scrollViewState = cloneDeep(state);

            scrollViewState.flowView.scrollView = {
                _id: action.itemId,
                parent: action.parent
            };

            if (action.beforeRender) scrollViewState.flowView.scrollView.beforeRender = true;

            return scrollViewState;

        case actionTypes.SET_SINGLE_ROOT_VIEW:

            let singleRootState = cloneDeep(state);

            singleRootState.flowView.singleRootView = action.value;

            return singleRootState;

        case actionTypes.SET_DETAIL_ITEM:
            let detailState = cloneDeep(state);

            const active = action.itemId? true : false;
            
            if (active){
                detailState.detailView.activeItem = action.itemId;
                detailState.detailView.active = active;

                detailState.detailView.past = [...state.detailView.past];

                if (state.detailView.activeItem) detailState.detailView.past.push(state.detailView.activeItem);
            }else{
                detailState.detailView = detailViewInit;
            }

            detailState.glimpseView = glimpseViewInit;

            return detailState;

        case actionTypes.DETAIL_TIME_TRAVEL:

            let timeTravelState = cloneDeep(state);

            timeTravelState.detailView.active = true;

            //Go Back
            if (action.index < 0){
                //Set current activeItem as next future item
                timeTravelState.detailView.future.push(timeTravelState.detailView.activeItem)

                //Remove most recent past item & set as activeItem
                const newActive = timeTravelState.detailView.past.pop();

                if (!newActive) return state;

                timeTravelState.detailView.activeItem = newActive;     

            }
            //Go Forward
            else if (action.index > 0){
                //Set current activeItem as next past item
                timeTravelState.detailView.past.push(timeTravelState.detailView.activeItem);

                //Remove most recent future item & set as activeItem
                const newActive = timeTravelState.detailView.future.pop();

                if (!newActive) return state;

                timeTravelState.detailView.activeItem = newActive;
            }

            return timeTravelState;

        case actionTypes.SET_SELECT_VIEW:
            let tagState = resetAllViews(cloneDeep(state));
            
            tagState.currentView = 'selectView';
            tagState.selectView.active = true;
            tagState.selectView.filters = action.filters;

            return tagState;

        case actionTypes.SET_SEARCH_VIEW:
            let searchState = resetAllViews(cloneDeep(state));

            searchState.currentView = 'searchView';
            searchState.searchView.active = action.items.length? true : false;
            searchState.searchView.items = action.items;

            return searchState;         

        case actionTypes.SET_ITEM_FORM:
            let setFormState = cloneDeep(state);

            if ((action.item || action.parent) 
                && setFormState.formState.item === action.item
                && setFormState.formState.parent === action.parent){ 

                setFormState.formState = formStateInit;
            }else{
                setFormState.formState = {
                    active: action.active,
                    item: action.item,
                    parent: action.parent
                } 
            }

            if (action.active) setFormState.modal = modalStateInit;

            return setFormState;

        case actionTypes.SET_GLIMPSE_VIEW:
            let glimpseState = cloneDeep(state);

            glimpseState.currentView='flowView';

            glimpseState.glimpseView={
                active: action.active,
                itemId: action.itemId,
                url: action.url,
                file: action.file
            }

            glimpseState.detailView = detailViewInit;

            return glimpseState;

        case actionTypes.SET_TRASH_VIEW:
            let trashState = resetAllViews(cloneDeep(state));

            trashState.currentView = 'trashView';

            trashState.trashView = true;

            return trashState;

        case actionTypes.RESET_VIEWS:
            const resetState = resetViews(cloneDeep(state));

            return resetState;
            
        default:
            return state;
    }
}

export default reducer;