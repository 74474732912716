import React from 'react';
import classes from './IllustrationWrapper.module.css';

const IllustrationWrapper = (props) =>{
    const containerClasses = props.classes ? [classes.Wrapper, ...props.classes] : [classes.Wrapper];

    const imageClasses = props.imageClasses ? [classes.Image, ...props.imageClasses] : [classes.Image];

    const textClasses = props.textClasses ? [classes.Text, ...props.textClasses] : [classes.Text];

    return( 
        <div
            className={containerClasses.join(' ')}
        >
            <img 
                height={props.height}
                width={props.width}
                alt={props.alt || ''}
                src={props.image} 
                className={imageClasses.join(' ')}
                />
            {
                props.text? <h3 className={textClasses.join(' ')}>{props.text}</h3>: null
            }
        </div>
    )
};

export default IllustrationWrapper;