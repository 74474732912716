import React from 'react';
import Button from '../Button/Button';
import ButtonContainer from '../ButtonContainer/ButtonContainer';
import classes from './ConfirmDialog.module.css'

const Confirm = (props) =>{

    const containerClasses = props.containerClasses ? [...props.containerClasses, classes.Container] : [classes.Container];
    const headerClasses = props.headerClasses ? [...props.headerClasses, classes.Header] : [classes.Header];
    const dialogClasses = props.dialogClasses ? [...props.dialogClasses, classes.Dialog] : [classes.Dialog];

    const dialog = props.dialog ? 
        <p
            className={dialogClasses.join(' ')}
        >
            {props.dialog}
        </p>
        : null;

    const header = props.header?
        <h4
            className={headerClasses.join(' ')}
        >
            {props.header}
        </h4>
        : null;
    

    const confirmDialog = (
        <div
            className={containerClasses.join(' ')}
        >
            {header}
            {dialog}
            
            <ButtonContainer
                classes={[classes.Buttons]}
            >
                {/* Types = Continue, Cancel, Delete */}
                <Button
                    btnType={props.confirmType? props.confirmType: 'Continue'}
                    clicked={props.confirm}
                >
                    {props.confirmText? props.confirmText : 'Continue'}
                </Button>
                <Button
                    btnType={props.cancelType ? props.cancelType : 'Cancel'}
                    clicked={props.cancel}
                >
                    {props.cancelText? props.cancelText : 'Cancel'}
                </Button>
            </ButtonContainer>
        </div>
    )

    return confirmDialog;
};

export default Confirm;