import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import FirstView from './FirstView/FirstView';
import Linking from './Highlights/Linking';
import View from './Highlights/View';
import Final from './Highlights/Final';
import HomeLayout from '../../hoc/HomeLayout/HomeLayout';

const Home = (props) =>{

    const {isAuthenticated} = props;

    const [user, setUser] = useState(null);

    useLayoutEffect(() => {
        const user = localStorage.getItem('user');
        if (user) setUser(user);
    }, []);
    
    return(
        <HomeLayout
            isAuthenticated={isAuthenticated}
            user={user}
        >
            <Helmet>
                <title>threadly</title>
            </Helmet>
            <FirstView
                isAuthenticated={isAuthenticated}
                user={user}
            />
            <Linking />
            <View />
            <Final />
        </HomeLayout>
    )

    
};

export default Home;