import React from 'react';
import classes from './Dots.module.css';

const Dots = (props) =>{
    let styles  = props.classes? [...props.classes, classes.Dots] : [classes.Dots];
    let divClasses = props.dotClasses? props.dotClasses : [];

    return (
        <div className={styles.join(' ')}>
            <div style={{backgroundColor: props.color}} className={[classes.bounce1, ...divClasses].join(' ')}></div>
            <div style={{ backgroundColor: props.color}} className={[classes.bounce2, ...divClasses].join(' ')}></div>
            <div style={{ backgroundColor: props.color}} className={[classes.bounce3, ...divClasses].join(' ')}></div>
        </div>
    )
};

export default Dots;