import React from 'react';
import Modal from '../UI/Modal/Modal';
import classes from './ErrorBoundaries.module.css';
import Button from '../UI/Button/Button';
import ButtonContainer from '../UI/ButtonContainer/ButtonContainer';


const ErrorTemplate = (props) => {

    const button = props.button && (props.clicked || props.resetErrorBoundary)?
        <Button
            clicked={() => {
                if (props.resetErrorBoundary) {
                    props.resetErrorBoundary();
                }

                if (props.clicked) {
                    props.clicked();
                }
            }}
        >
            {props.button}
        </Button>
        : null;

    return (
        <Modal
            visible={true}
            backgroundClasses={[classes.Background]}
            classes={[classes.Modal]}
        >
            <div
                className={classes.ErrorContainer}
            >
                <div
                    className={classes.ImageContainer}
                >
                    <img className={classes.Image} src={props.src} alt={props.alt || ''}/>
                </div>
                <div
                    className={classes.Details}
                >
                    <p
                        className={classes.Message}
                    >{props.message}</p>
                    <ButtonContainer >
                        {button}
                        {props.children}
                    </ButtonContainer>
                </div>
            </div>
        </Modal>
    )
};

export default ErrorTemplate;