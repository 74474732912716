import queryString from 'query-string';

const options = {
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: '|'
}

export const stringifyQuery = (object) => {
    return queryString.stringify(object, options)
};

export const parseQuery = (string) => {
    return queryString.parse(string, options)
}

export const stringifyFilters = (filters) => {
    if (!filters) filters = [];
    const stringedFilters = filters.map((filter) => {
        return stringifyQuery(filter);
    });

    const filterString = stringifyQuery({ filters: stringedFilters });

    return filterString;
}

export const parseFilterQuery = (query) => {
    if (!query) return [];
    let { filters } = parseQuery(query);

    if (!Array.isArray(filters)) return [];

    let filterArray = [];

    if (filters.length) {
         filterArray = filters.map((filter) => {
            return parseQuery(filter);
        })
    }

    return filterArray;
}

export const parsePageName = (pathname) => {
    if (!pathname) return null;

    const page = pathname.split("/").pop();

    return page;
}

export const urlFromLocation = (location) => {
    if(!location) return null;
    return location.pathname + location.search + location.hash;
};