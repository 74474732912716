import axios from '../../axios-config';
import * as actionTypes from './actionTypes';
import authErrorHandler from './errorHandlers/authErrorHandler';

const authStart = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.AUTH_START
        })
    }
}

const authSuccess = (user) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.AUTH_SUCCESS,
            user
        });
    }
}

const authFail = (error) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.AUTH_FAIL,
            error
        })
    }
}

const authLogout = () => {
    return (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_BASE}/auth/logout`)
            .then(() => {
                dispatch({
                    type: actionTypes.AUTH_LOGOUT
                })
            })
            .catch((error) => {
                authErrorHandler(dispatch, error);
            });
        axios.defaults.headers.common['Authorization'] = null;
    }
}

const setAuthRedirect = (redirect) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_AUTH_REDIRECT,
            redirect
        })
    }
}

const authenticate = (isSignup, email, password, name) => {
    return (dispatch) => {
        dispatch(authStart());
        
        let authData = {
            email,
            password,
            name
        };

        let authURL = `${process.env.REACT_APP_API_BASE}/auth/login`
        if (isSignup){
            authURL = `${process.env.REACT_APP_API_BASE}/auth/signup`
        }
        axios.post(authURL, authData)
            .then((response) => {
                const user = response.data.user;
                dispatch(authSuccess(user));
            })
            .catch((error) => {
                authErrorHandler(dispatch, error);
            })
    }
}

const authenticateGoogle = (isSignup, token) => {
    return (dispatch) => {
        dispatch(authStart());

        let authURL = `${process.env.REACT_APP_API_BASE}/auth/google/login`
        if (isSignup) {
            authURL = `${process.env.REACT_APP_API_BASE}/auth/google/signup`
        }

        axios.post(authURL, {token})
            .then((response) => {
                const user = response.data.user;
                dispatch(authSuccess(user));
            })
            .catch((error) => {
                authErrorHandler(dispatch, error);
            })
    }
}

const setAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(authRefresh());
        }, expirationTime);
    };
};

const authRefresh = (init=false) => {
    return (dispatch) => {
        dispatch(authStart());
        axios.post(`${process.env.REACT_APP_API_BASE}/auth/refresh`)
            .then((response) => {
                const user = response.data.user;
                dispatch(authSuccess(user));
            }).catch((error) => {
                if (init){
                    dispatch(authFail());
                }else{
                    authErrorHandler(dispatch, error);
                }
            });
    }
}


export {
    authenticate,
    authenticateGoogle,
    authStart,
    authSuccess,
    authFail,
    authLogout,
    authRefresh,
    setAuthTimeout,
    setAuthRedirect
}