import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/UI/Loading/Loading';
import HomeLayout from '../../hoc/HomeLayout/HomeLayout';
import lazyRetry from '../../utils/lazyImportWithRetry';

const AuthForm = lazyRetry(() => import('./AuthForm/AuthForm'), 'AuthForm');

const Auth = ({query, redirect}) =>{
    return (
        <HomeLayout
            isAuthenticated={false}
            user={null}
        >
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route path="/auth/signup" >
                        <Helmet>
                            <title>threadly | Sign Up</title>
                        </Helmet>
                        <AuthForm
                            userId={query.get('id')}
                            token={query.get('token')}
                            redirect={redirect}
                            signUp={true}
                        />
                    </Route>
                    <Route path="/auth" >
                        <Helmet>
                            <title>threadly | Login</title>
                        </Helmet>
                        <AuthForm
                            userId={query.get('id')}
                            token={query.get('token')}
                            redirect={redirect}
                            signUp={false}
                        />
                    </Route>
                </Switch>
            </Suspense>

        </HomeLayout>
    )
};

export default Auth;