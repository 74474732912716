import itemStateReducer from '../reducers/items';
import viewStateReducer from '../reducers/view';
import authStateReducer from '../reducers/auth';
import selectStateReducer from '../reducers/selected';

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    itemState: itemStateReducer,
    viewState: viewStateReducer,
    authState: authStateReducer,
    selectState: selectStateReducer
});

export default createRootReducer;