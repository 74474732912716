import React from 'react';
import Aux from '../../../../../hoc/_Aux/_Aux';

import classes from './FileInput.module.css';

const FileInput = React.forwardRef((props, ref) =>{
    const acceptTypes = props.accept? props.accept : ['.*'];

    const fileInputClasses = [classes.FileInput];
    const fileInput =( 
        <input 
            ref={ref}
            id={props.id}
            name='file'
            type='file' 
            className={fileInputClasses.join(' ')}
            accept={acceptTypes.join(',')}
            onChange={props.changeHandler}
        />
    );

    const addFile = (
           <Aux>
            {fileInput}
            <label
                htmlFor={props.id}
                className={classes.FileInputLabel}
            >
                {props.children}
            </label>
           </Aux>
    );

    return addFile;
});

export default FileInput;