import React, { useLayoutEffect, useState, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet-async';
import lazyRetry from './utils/lazyImportWithRetry';

import * as actions from './store/actions/index';
import selectors from './reselect/selectors';
import { urlFromLocation } from './utils/queryString';
import Home from './pages/Home/Home';
import Loading from './components/UI/Loading/Loading';
import EmptyView from './containers/Forms/EmptyView/EmptyView';
import Auth from './pages/Auth/Auth';
import gsap, {ScrollTrigger} from 'gsap/all';
import Dots from './components/Preloaders/Dots/Dots';
import classes from './App.module.css'


const Layout = lazyRetry(() => import('./hoc/Layout/Layout'), 'Layout');
const FlowView = lazyRetry(() => import('./containers/FlowView/FlowView'), 'FlowView');
const SelectView = lazyRetry(() => import('./containers/SelectView/SelectView'), 'SelectView');

const SearchView = lazyRetry(() => import('./containers/SearchView/SearchView'), 'SearchView');
const TrashView = lazyRetry(() => import('./containers/TrashView/TrashView'), 'TrashView');

const App = (props) =>{

  const [touch, setTouch] = useState(false);

  useEffect(() => {
    if (touch) return;

    const listener = window.addEventListener('touchstart', function onFirstTouch() {
      
      setTouch(true);

      // we only need to know once that a human touched the screen, so we can stop listening now
      window.removeEventListener('touchstart', onFirstTouch, false);
    }, false);

    return () => {
      window.removeEventListener('touchstart', listener);
    }
  });

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  useLayoutEffect(() => {
    props.onCheckAuth();
  }, []);

  const location = useLocation();

  //If initial navigation is to url within /app, redirect to that location on app opening
  let redirect = null;

  if (location.pathname.includes('/app/')){
    redirect = urlFromLocation(location);
  }

  const [redirectURL] = useState(redirect);

  let routes = null;

  // If /app exactly, maintain last view redirection
  const appRoute = location.pathname.includes('/app');

  if (props.init && (props.isAuthenticated || redirect || appRoute)) return <Loading />;

  else if (!props.isAuthenticated) {
    //getQuery for email validation/password reset
    const query = new URLSearchParams(location.search);

    routes = [
      <Route path='/auth' >
        <Auth 
          query={query}
          redirect={redirectURL}
        />
      </Route>,
      <Redirect to='/auth' />
    ]
  }

  else if (props.isAuthenticated) {
    routes = (
      <Suspense fallback={<Loading />}>

      <Route path='/app'>
        <Layout
          redirect={redirectURL}
          touch={touch}
        >
          {props.loading ? <Loading /> : null}
          {props.pendingAction && !props.loading ?
              <Dots
                classes={[classes.Pending]}
                dotClasses={[classes.Dots]}
              />
              : null}

          <Suspense fallback={<Loading />}>

            <Route path="/app/search" component={SearchView} />

            <Route path="/app/trash" component={TrashView} />

            <Route path="/app/view" >
              <SelectView filters={props.filters} />
            </Route>

            <Route path='/app/items'>
              <FlowView key={props.activeRoots?.join('')} />
            </Route>
            
          </Suspense>

          <Route path='/app/' exact >
            {props.loading || props.pendingAction || (redirectURL && redirectURL !== '/app/') ? null : <EmptyView />}
          </Route>

        </Layout>
      </Route>

      <Redirect to="/app/" />

      </Suspense>

    )
  }    

  return (
    <div className={classes.App}>
        <Helmet prioritizeSeoTags>
          <title>threadly</title>
          <meta
            name="description"
            content="A thread-based bookmark manager. Connect your links, files and notes together in a way that makes sense to you. No more dumping links in a folder...create a thread of links to save your train of thought."
          />
          <meta property='og:url' content='https://threadly.chasenstark.com' />
          <meta property='og:title'
            content='threadly'
          />
          <meta property='og:description' 
            content='A thread-based bookmark manager. Connect your links, files and notes together in a way that makes sense to you. No more dumping links in a folder...create a thread of links to save your train of thought.'
          />
          <meta name="viewport"
          content="width=device-width, initial-scale=1.0" />
        </Helmet>
        <Switch>
          <Route path='/' exact>
            <Home 
              isAuthenticated={props.isAuthenticated}
              redirect={redirectURL}
            />
          </Route>
          {routes}
          <Redirect to='/' />
        </Switch>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authState.user !== null,
    init: state.authState.init,
    loading: state.itemState.loading,
    pendingAction: state.itemState.pendingAction,
    activeRoots: selectors.activeRootsSelector(state),
    filters: selectors.filterSelector(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    onCheckAuth: () => {dispatch(actions.authRefresh(true))},
    onGetItems: (redirect=null) => {dispatch(actions.getItems(redirect))}
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);