import * as actionTypes from './actionTypes';
import debounce from 'lodash.debounce';
import axios from '../../axios-config';
import { push, replace } from 'connected-react-router';
import { stringifyFilters, stringifyQuery, urlFromLocation } from '../../utils/queryString';
import selectors from '../../reselect/selectors';
import { batch } from 'react-redux';

const saveViewState = debounce((viewState, router) => {
    axios.post(`${process.env.REACT_APP_API_BASE}/user/view`, {
        viewState,
        route: urlFromLocation(router.location)
    }).catch((error) => {
    })
}, 1000, {leading: false, trailing: true});


export const loadingView = (value=false) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.LOADING_VIEW,
            value
        })
    }
}


//LAYOUT ACTIONS

export const setModal = (contents) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_MODAL,
            contents
        })
    }
}


//FLOWVIEW ACTIONS

export const activeAncestry = (item) => {
    return (dispatch, getState) => {

        if (!item) return;

        const checkScrollState = getState();

        // const scrollViewId = checkScrollState.viewState.flowView.scrollView?._id;

        // if (scrollViewId){
        //     const scrollViewItem = checkScrollState.itemState.items[scrollViewId];

        //     if (item.ancestors || item.parent){
        //         if(item.ancestors && !isEqual(scrollViewItem?.ancestors, item.ancestors)) return;
        //         if(item.parent && scrollViewItem?.parent !== item.parent) return;
        //     }
        // }


        let hashString;

        if (!item || !item.ancestors || !item.ancestors.length) hashString = "";
        else hashString = item._id;

        if (hashString) hashString = stringifyQuery({active: hashString});

        //Only update router if hash is different
        if (hashString !== checkScrollState.router.location.hash){

            dispatch(replace({ ...checkScrollState.router.location, hash: hashString }));

        }

        // dispatch({
        //     type: actionTypes.ACTIVE_ANCESTRY,
        //     parent,
        //     ancestors
        // })

        const { viewState, router } = getState();
        saveViewState(viewState, router);
    }
}

export const ancestrySettings = (settings) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ANCESTRY_SETTINGS,
            settings
        })
    }
}

export const setFlowViewRoots = (ids, activeItem) => {
    const roots = Array.isArray(ids)? ids : [ids];
    return (dispatch, getState) => {

        let pushAction;

        //Provide ActiveItem to set Ancestry
        if (activeItem){
            let hashString;

            if (!activeItem || !activeItem.ancestors || !activeItem.ancestors.length) hashString = null;
            else hashString = activeItem._id;

            if (hashString) hashString = stringifyQuery({ active: hashString });

            pushAction = push({
                pathname: '/app/items',
                search: stringifyQuery({
                    items: roots
                }),
                hash: hashString
            });
        }else{
            pushAction = push({
                pathname: '/app/items',
                search: stringifyQuery({
                    items: roots
                })
            });
        }
        
        batch(() => {
            dispatch(pushAction);

            dispatch({
                type: actionTypes.SET_FLOW_VIEW_ROOT,
                roots
            });
        })

        const { viewState, router } = getState();
        saveViewState(viewState, router);
    }
}

export const setScrollView = (itemId, beforeRender=false, parentId=null) => {
    return (dispatch, getState) => {
        const state = getState();

        const item = state.itemState.items[itemId];

        const activeRoots = selectors.activeRootsSelector(state);
        
        let rootAction;

        if(item){
            const ancestors = item.ancestors?.length? item.ancestors : [item._id];


            if (!activeRoots || !ancestors.some((ancestor) => {
                return activeRoots?.includes(ancestor)})
            ) {
                const ancestor = item.ancestors[0] || item._id;

                rootAction = setFlowViewRoots([ancestor]);
                beforeRender = true;
            }
        }else if (parentId){
            const fullParent = state.itemState.items[parentId];

            const children = fullParent.children || [];

            //Check if activeRoot is anywhere around parentId
            const anyPossibleRoot = fullParent.ancestors?.length? [...fullParent.ancestors, fullParent._id, ...children] : [fullParent._id, ...children];


            if (!activeRoots || !anyPossibleRoot.some((ancestor) => { return activeRoots.includes(ancestor) })) {
                const ancestor = fullParent.ancestors[0] || fullParent._id;

                rootAction = setFlowViewRoots([ancestor]);
                beforeRender=true;
            }
        }

        batch(() => {
            if (rootAction) dispatch(rootAction);
            
            dispatch({
                type: actionTypes.SET_SCROLL_VIEW,
                itemId,
                beforeRender,
                parent: parentId
            });
        })
    }
}

export const setSingleRootView = (value) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.SET_SINGLE_ROOT_VIEW,
            value
        })

        const { viewState, router } = getState();
        saveViewState(viewState, router);
    }
}



//DETAIL VIEW ACTIONS

export const setDetailItem = (itemId) => {
    return (dispatch) => {
        dispatch(
            {
                type: actionTypes.SET_DETAIL_ITEM,
                itemId
            }
        );
    }
}

export const detailTimeTravel = (index) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.DETAIL_TIME_TRAVEL,
            index
        })
    }
}


//GLIMPSE VIEW

export const setGlimpseView = (active=false, item=null, url=null, file=null) => {
    return (dispatch, getState) => {

        const {viewState, itemState} = getState();
        
        const itemId = active? item._id : null;

        let rootAction;
            
        if (itemId){
            let item = itemState.items[itemId];

            if (item){

                const ancestor = item.ancestors[0] || itemId;

                if (ancestor && !viewState.flowView.roots.includes(ancestor)) {
                    rootAction = setFlowViewRoots([ancestor]);
                }
            }            
        }

        batch(() => {
            if (rootAction) dispatch(rootAction);
            dispatch({
                type: actionTypes.SET_GLIMPSE_VIEW,
                active,
                itemId,
                url,
                file
            });
            if (item) dispatch(setScrollView(item._id, true));
        })
    }
}



//SELECT VIEW
export const setSelectView = (filters) => {
    return (dispatch, getState) => {

        const filterString = stringifyFilters(filters);

        batch(() => {
            dispatch(push(`/app/view?${filterString}`));

            dispatch(resetViews())
        })

        const { viewState, router } = getState();
        saveViewState(viewState, router);
    }
}

//SEARCH VIEW
export const setSearchView = (query, items) => {
    return (dispatch) => {

        batch(() => {
            dispatch(push(`/app/search?q=${query}`));

            dispatch({
                type: actionTypes.SET_SEARCH_VIEW,
                items
            })
        })
    }
}

//SET TRASH VIEW
export const setTrashView = () => {
    return (dispatch) => {
        batch(() => {
            dispatch(push('trash'));
            dispatch(resetViews())
        })
    }
}



//FORM ACTIONS

export const setItemForm = (active, item, parent) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_ITEM_FORM,
            active,
            item,
            parent
        });
    }
}

//RESET VIEWS

export const resetViews = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.RESET_VIEWS
        })
    }
}

