import * as actionTypes from './actionTypes';
import { batch } from "react-redux";
import axios from '../../axios-config';
import itemErrorHandler from './errorHandlers/itemErrorHandler';
import { activeAncestry, setDetailItem, setItemForm, setFlowViewRoots } from './viewActions';
import { debounce } from 'lodash';
import { replace } from 'connected-react-router';

const pendingAction = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PENDING_ACTION
        });
    }
}

const getItems = (redirect) => {
    return (dispatch) => {
        dispatch(pendingAction());

        axios.get(`${process.env.REACT_APP_API_BASE}/items`)
            .then((res) => {
                if (res.status !== 200) throw Error('Error retrieving items.')

                batch(() => {
                    dispatch({
                        type: actionTypes.INIT_STATE,
                        items: res.data.items,
                        deletedItems: res.data.deletedItems,
                        sharedItems: res.data.sharedItems,
                        tags: res.data.tags,
                        roots: res.data.roots,
                        importPending: res.data.importPending
                    });

                    try {
                        if (redirect) {
                            dispatch(replace(redirect));
                        }
                        else {
                            const route = res.data.route;
                            if (route){
                                dispatch(replace(route));
                            }
                        }


                        dispatch({
                            type: actionTypes.INIT_VIEW_STATE,
                            viewState: res.data.viewState
                        });

                    } catch (error) {
                        //Catch Init View Errors
                    }
                })

                if (res.data.importPending){
                    setTimeout(() => {
                        dispatch(checkImport());
                    }, 10000);
                }
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            });
    }
}

const newItem = (formData) => {
    return (dispatch) => {

        axios.post(`${process.env.REACT_APP_API_BASE}/item`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
            .then((res) => {

                if (res.status !== 201) throw Error('Item not stored');

                const item = res.data.item;
                const tags = res.data.tags;
                
                dispatch({
                    type: actionTypes.NEW_ITEM,
                    item: item,
                    tags: tags
                });

                //GO TO NEW THREAD UPON CREATION
                if (item._id && !item.parent){
                    dispatch(setFlowViewRoots(item._id));
                }
            })
            .catch((error) => {
                const retry = () => {
                    return dispatch(newItem(formData))
                };

                dispatch(itemErrorHandler(error, retry));
            });

        dispatch({
            type: actionTypes.PENDING_ACTION
        });
    }
}

const editItem = (formData) => {//_id, body, sharing
    return (dispatch) => {

        axios.put(`${process.env.REACT_APP_API_BASE}/item`, formData)
            .then((res) => {
                if (res.status !== 200) throw Error('Item not stored');
                dispatch({
                    type: actionTypes.EDIT_ITEM,
                    item: res.data.item,
                    tags: res.data.tags
                });
            })
            .catch((error) => {
                const retry = () => {
                    return dispatch(editItem(formData))
                };

                dispatch(itemErrorHandler(error, retry));
            });

        dispatch({
            type: actionTypes.PENDING_ACTION
        });        
    }
}

const editItemTags = (itemId, tags) => {
    return (dispatch) => {
        dispatch(pendingAction());

        axios.post(`${process.env.REACT_APP_API_BASE}/item/tags`, {item: itemId, tags})
        .then((res) => {
            dispatch({
                type: actionTypes.EDIT_ITEM,
                item: res.data.item,
                tags: res.data.tags
            });
        })
        .catch((error) => {
            dispatch(itemErrorHandler(error));
        })
    }
}

const deleteItem = (itemId) => {

    return (dispatch, getState) => {

        //If ActiveAncestry Includes Any Deleted Items, Reset To Closest Ancestor

        const { itemState } = getState();

        const item = itemState.items[itemId];

        ///FIX
        batch(() => {
            dispatch(activeAncestry(item.parent, item.ancestors));

            //Delete Item
            dispatch({
                type: actionTypes.DELETE_ITEM,
                item: {
                    _id: itemId
                }
            });

            dispatch(setDetailItem(null));
        })
        
        axios.delete(`${process.env.REACT_APP_API_BASE}/item`, { params: {_id: itemId} })
            .then((res) => {
                dispatch({
                    type: actionTypes.VERIFY_ITEM_STATE,
                    updates: res.data.updates
                })
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            });

        // //Scroll to Active Ancestry
        // dispatch(setScrollView(item.parent));
    }
}

const deleteBranch = (itemId) => {

    return (dispatch, getState) => {

        //If ActiveAncestry Includes Any Deleted Items, Reset To Closest Ancestor

        const {itemState} = getState();

        const item = itemState.items[itemId];

        batch(() => {
            dispatch(activeAncestry(item.parent, item.ancestors));

            //Delete Branch
            dispatch({
                type: actionTypes.DELETE_BRANCH,
                item: { _id: itemId }
            });

            dispatch(setDetailItem(null));
        })

        axios.delete(`${process.env.REACT_APP_API_BASE}/item/branch`, { params: { _id: itemId } })
            .then((res) => {
                dispatch({
                    type: actionTypes.VERIFY_ITEM_STATE,
                    updates: res.data.updates
                })
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            });

        // //Scroll to Active Ancestry
        // dispatch(setScrollView(item.parent));


    }
}

const moveItem = (parentId, itemId) => {
    return (dispatch) => {

        dispatch({
            type: actionTypes.MOVE_ITEM,
            _id: itemId,
            newParent: parentId
        });

        axios.patch(`${process.env.REACT_APP_API_BASE}/item`, {
            _id: itemId,
            newParent: parentId
        })
            .then((res) => {
                dispatch({
                    type: actionTypes.VERIFY_ITEM_STATE,
                    updates: res.data.updates
                })
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            });
    }
}

const moveBranch = (parentId, itemId) => {
    return (dispatch) => {

        dispatch({
            type: actionTypes.MOVE_BRANCH,
            _id: itemId,
            newParent: parentId
        });

        axios.patch(`${process.env.REACT_APP_API_BASE}/item/branch`, {
            _id: itemId,
            newParent: parentId
        })
            .then((res) => {
                dispatch({
                    type: actionTypes.VERIFY_ITEM_STATE,
                    updates: res.data.updates
                })
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            });
    }
}

const updateNote = (item, note) => {
    return (dispatch) => {

        axios.patch(`${process.env.REACT_APP_API_BASE}/item/note`, {
            _id: item._id,
            note
        })
            .then((res) => {
                dispatch({
                    type: actionTypes.VERIFY_ITEM_STATE,
                    updates: res.data.updates
                });
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            });

        dispatch({
            type: actionTypes.UPDATE_NOTE,
            item,
            note
        });
    }
}

const deleteFile = (fileId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PENDING_ACTION
        });

        axios.delete(`${process.env.REACT_APP_API_BASE}/file`, {params: {fileId: fileId}})
            .then((res) => {
                dispatch(
                    {
                        type: actionTypes.EDIT_ITEM,
                        item: res.data.item
                    }
                )
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const favoriteItem = (item) => {
    if (!item.db.saved || item.db.submitted) return;
    return (dispatch) => {

        dispatch({
            type: actionTypes.FAVORITE_ITEM,
            item
        });

        axios.post(`${process.env.REACT_APP_API_BASE}/item/favorite`, {_id: item._id})
            .then((res) => {
                dispatch({
                    type: actionTypes.VERIFY_ITEM_STATE,
                    updates: res.data.updates
                });
            }).catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const itemOrderCall = debounce((dispatch, item, hoverIndex) => {
    axios.patch(`${process.env.REACT_APP_API_BASE}/item/order`, { item, index: hoverIndex })
        .catch((error) => {
            dispatch(itemErrorHandler(error));
        });
}, 500, {leading: false, trailing: true});

const setItemOrder = (item, dragIndex, hoverIndex) => {
    return (dispatch) => {
        itemOrderCall(dispatch, item, hoverIndex);

        dispatch({
            type: actionTypes.SET_ITEM_ORDER,
            item,
            dragIndex,
            hoverIndex
        });
    }
}


//THIS SETS ERROR MODAL IN LAYOUT 
const itemError = (active, modalType, action, message) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ITEM_ERROR,
            active,
            modalType,
            action,
            message
        });
    }
}

const selectItem = (itemId, reset) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SELECT_ITEM,
            itemId,
            reset
        });
    }
}

const selectAll = (itemIds) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SELECT_ALL,
            itemIds
        })
    }
}

const clearSelected = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLEAR_SELECTED
        })
    }
}

const clickItem = (itemId) => {
    return (dispatch, getState) => {
        let selectionActive = Boolean(getState().selectState.items.length);

        if (selectionActive){
            dispatch(selectItem(itemId));
        }else {
            dispatch(setDetailItem(itemId));
        }
        return selectionActive;
    }
}

const moveMultipleItems = (parentId) => {
    return (dispatch, getState) => {
        const selectedItems = getState().selectState.items;

        dispatch(pendingAction());

        axios.patch(`${process.env.REACT_APP_API_BASE}/items`, {
            items: selectedItems,
            newParent: parentId
        })
            .then((res) => {
                dispatch(implementUpdates(res.data))
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const moveMultipleBranches = (parentId) => {
    return (dispatch, getState) => {
        const selectedItems = getState().selectState.items;

        dispatch(pendingAction());

        axios.patch(`${process.env.REACT_APP_API_BASE}/items/branch`, {
            items: selectedItems,
            newParent: parentId
        })
            .then((res) => {
                dispatch(implementUpdates(res.data))
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}


const favoriteMultipleItems = (ids) => {
    return (dispatch, getState) => {

        const selectedItems = ids? ids : getState().selectState.items;

        axios.post(`${process.env.REACT_APP_API_BASE}/items/favorite`, {
            items: selectedItems
        })
            .then((res) => {
                dispatch(implementUpdates(res.data))
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const tagMultipleItems = (tags, items) => {
    return (dispatch, getState) => {
        const selectedItems = items && items?.length? items : getState().selectState.items;

        dispatch(pendingAction());

        axios.patch(`${process.env.REACT_APP_API_BASE}/items/tags`,
            {
                items: selectedItems,
                tags
            })
            .then((res) => {
                dispatch(implementUpdates(res.data))
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const deleteMultipleItems = () => {
    return (dispatch, getState) => {
        const selectedItems = getState().selectState.items;

        dispatch(pendingAction());

        axios.delete(`${process.env.REACT_APP_API_BASE}/items`, { params: { items: selectedItems }})
            .then((res) => {
                dispatch(implementUpdates(res.data))
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const deleteMultipleBranches = () => {
    return (dispatch, getState) => {
        const selectedItems = getState().selectState.items;

        dispatch(pendingAction());

        axios.delete(`${process.env.REACT_APP_API_BASE}/items/branch`, { params: { items: selectedItems } })
            .then((res) => {
                dispatch(implementUpdates(res.data))
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const permanentDelete = (id) => {
    return (dispatch, getState) => {

        let selectedItems = id ? [id] : getState().selectState.items;

        dispatch(pendingAction());

        axios.delete(`${process.env.REACT_APP_API_BASE}/items/permanent`, { params: { items: selectedItems } })
            .then((res) => {
                let updates = res.data.updates;

                batch(() => {
                    dispatch({
                        type: actionTypes.PERMANENT_DELETE,
                        updates
                    });
                    dispatch(clearSelected());
                    dispatch(setItemForm(false));
                })                
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const permanentDeleteAll = () => {
    return (dispatch) => {
        dispatch(pendingAction());

        axios.delete(`${process.env.REACT_APP_API_BASE}/trash/all`)
            .then((res) => {
                let updates = res.data.updates;

                batch(() => {
                    dispatch({
                        type: actionTypes.PERMANENT_DELETE,
                        updates
                    });
                    dispatch(setItemForm(false));
                })               
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const refreshScreenshot = (itemId) => {
    return (dispatch) => {
        axios.post(`${process.env.REACT_APP_API_BASE}/item/screenshot`, {
            _id: itemId
        })
            .then((res) => {
                dispatch(implementUpdates(res.data));
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            })
    }
}

const implementUpdates = (response) => {
    return (dispatch) => {
        let updates = response.updates;
        let tags = response.tags;
        let roots = response.roots;

        dispatch({
            type: actionTypes.IMPLEMENT_UPDATES,
            updates,
            tags,
            roots
        })
    }
}

const editTag = (tag) => {
    return (dispatch) => {
        axios.put(`${process.env.REACT_APP_API_BASE}/tag/edit`, {tag})
            .then((res) => {
                const tags = res.data.tags

                dispatch({
                    type: actionTypes.UPDATE_TAGS,
                    tags
                });

            })
            .catch((error) => {
                dispatch(itemErrorHandler(error))
            })
    }
}

const deleteTag = (tag) => {
    return (dispatch) => {
        axios.delete(`${process.env.REACT_APP_API_BASE}/tag`, {
            params: {
                tag: tag._id
            }
        })
        .then((res) => {
            const tags = res.data.tags;
            const updates = res.data.updates;

            dispatch({
                type: actionTypes.DELETE_TAG,
                updates,
                tags
            })
        })
        .catch((error) => {
            dispatch(itemErrorHandler(error))
        })
    }
}

const importBookmarks = (formData) => {
    return (dispatch) => {

        dispatch({
            type: actionTypes.PENDING_ACTION
        });

        axios.post(`${process.env.REACT_APP_API_BASE}/import`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(() => {
                dispatch({
                    type: actionTypes.IMPORT_BOOKMARKS,
                    importPending: true
                });


                return setTimeout(() => {
                    dispatch(checkImport());
                }, 10000);
            })
            .catch((error) => {
                dispatch(itemErrorHandler(error));
            });
    }
}

const checkImport = () => {
    return (dispatch) => {
        
        axios.get(`${process.env.REACT_APP_API_BASE}/import`)
        .then((res) => {
            if (res.status === 204){
                return setTimeout(() => {
                    dispatch(checkImport());
                }, 10000);

            }else if (res.status === 201){
                const {items, roots} = res.data;

                dispatch({
                    type: actionTypes.IMPORT_BOOKMARKS,
                    importPending: false,
                    items,
                    roots
                });
            }
        })
        .catch((error) => {
            dispatch(itemErrorHandler(error));
        })
    }
}

export {
    pendingAction,
    getItems,
    newItem,
    editItem,
    editItemTags,
    deleteItem,
    deleteBranch,
    moveItem,
    moveBranch,
    updateNote,
    deleteFile,
    favoriteItem,
    setItemOrder,
    itemError,
    selectItem,
    selectAll,
    clearSelected,
    clickItem,
    implementUpdates,
    moveMultipleItems,
    moveMultipleBranches,
    favoriteMultipleItems,
    tagMultipleItems,
    deleteMultipleItems,
    deleteMultipleBranches,
    permanentDelete,
    permanentDeleteAll,
    editTag,
    deleteTag,
    refreshScreenshot,
    importBookmarks,
    checkImport
}