import { lazy } from "react";

function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const lazyRetry = (importFn, componentName,retries = 2, interval = 1000) => {
    return lazy(async () => {

        const forcedRefresh = JSON.parse(
            window.localStorage.getItem(
                `forced-refresh-${componentName}`
            ) || 'false'
        );

        try {   
            const component = await importFn();

            window.localStorage.setItem(
                `forced-refresh-${componentName}`,
                'false'
            );

            return component;

        } catch (error) {
            if (retries) {
                await wait(interval);
                return lazyRetry(importFn, retries - 1, interval);
            } else {
                if (forcedRefresh){
                    throw new Error(error);
                }else{
                    window.localStorage.setItem(
                        `forced-refresh-${componentName}`,
                        'false'
                    );
                    window.location.reload();
                }
            }
        }
    })
}

export default lazyRetry;