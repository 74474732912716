import React from 'react';
import classes from '../HomeLayout.module.css'
import NavButton from './NavButton';
import { NavLink } from 'react-router-dom';

const NavBar = (props) =>{

    const logo = (
        <NavLink
        to='/'
        className={classes.Logo}>
            <h1>threadly</h1>
            {/* <img src={logoImg} /> */}
        </NavLink>
    )

    const button = <NavButton 
        isAuthenticated={props.isAuthenticated}
        user={props.user}
        containerClasses={[classes.NavButtons]}
        />;

    return (
        <nav className={classes.NavBar}>
            {logo}
            {button}
        </nav>
    )
};

export default NavBar;