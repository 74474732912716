import { IoAddCircleSharp, IoAddOutline, IoAttach, IoCameraReverseOutline, IoCaretBackOutline, IoCaretDown, IoCaretForward, IoCaretForwardOutline,  IoCaretUp,  IoCheckboxOutline,  IoCloseOutline, IoCloudDone, IoCloudUpload,  IoCreateOutline, IoDesktop, IoDocumentText, IoEllipsisVertical, IoEyeOutline, IoFilm, IoGitMergeOutline, IoGrid, IoHeart, IoHome, IoImage, IoLinkOutline, IoLogoBuffer, IoLogOut, IoMoveOutline, IoPerson, IoPricetag, IoReader, IoRemoveOutline, IoSearch, IoSettings, IoSquareOutline, IoSyncCircle, IoTrash, } from 'react-icons/io5';

import { AiOutlineAppstoreAdd } from "react-icons/ai";

import { BsArrowsAngleContract, BsArrowsAngleExpand, BsArrowUpRightSquare, BsDiagram3, BsDownload, BsFileEarmarkPlus, BsFolderFill, BsHash, BsList, BsNodePlus, BsPlusLg, BsSearch, BsUiChecksGrid } from "react-icons/bs";



import IconWrap from './IconWrapper';

const DEFAULT = <IoLogoBuffer />

const EDIT = <IoCreateOutline  />;

const FILE = <IoDocumentText  />;

const FOLDER = <BsFolderFill stroke={0} style={{ fill: 'white' }} />;

const IMAGE = <IoImage  />;

const LINK = <IoLinkOutline  />;

const PLUS = <IoAddOutline />;

const ADD_CIRCLE = <IoAddCircleSharp />;

const ADD_ITEM = <BsPlusLg />;

const ADD_NODE = <BsNodePlus />

const VIDEO = <IoFilm  />;

const DELETE = <IoTrash  />;

const CLOSE = <IoCloseOutline  />;

const LINK_OUT = <BsArrowUpRightSquare stroke={0} style={{ fill: 'black' }} />;

const VIEW = <IoEyeOutline  />;

const EXPAND = <BsArrowsAngleExpand stroke={0} style={{fill: 'black'}}/>;

const COLLAPSE = <BsArrowsAngleContract stroke={0} style={{ fill: 'black' }}/>;

const THREE_DOTS = <IoEllipsisVertical  />;

const ATTACH = <IoAttach  />;

const STAR = <IoHeart />;

const NOTE = <IoReader  />;

const ADD_FILE = <BsFileEarmarkPlus stroke={0} style={{ fill: 'black' }}/>;

const ADD_ROOT = <AiOutlineAppstoreAdd />

const LOADING = <IoSyncCircle  />;

const GRID = <IoGrid  />;  

const GRID_CHECK = <BsUiChecksGrid stroke={0} style={{ fill: 'black' }}/>;

const LOGOUT = <IoLogOut  />;

const HOME = <IoHome  />;

const SIDEBAR_OPEN = <IoCaretForwardOutline  />;

const SIDEBAR_CLOSE = <IoCaretBackOutline  />;

const SIDEBAR = <BsList stroke={0} style={{ fill: 'black' }}/>;

const SAVE = <IoCloudUpload  />;

const UPLOAD = <IoCloudUpload />;

const SETTINGS = <IoSettings />;

const SAVED = <IoCloudDone  />;

const SAVING = <IoCloudUpload  />;

const TAG = <IoPricetag  />;

const MOVE = <IoMoveOutline  />;

const DROP = <IoGitMergeOutline  />;

const MINIMIZE = <IoRemoveOutline  />;


const TREE = <BsDiagram3 stroke={0} style={{fill: 'var(--text-color-black)'}}/>;

const CHECKED = <IoCheckboxOutline />;

const UNCHECKED = <IoSquareOutline  />;

const USER = <IoPerson/>;

const LEFTARROW = <IoCaretBackOutline />;
const RIGHTARROW = <IoCaretForward />;
const DOWNARROW = <IoCaretDown />;
const UPARROW = <IoCaretUp />

const SEARCH = <IoSearch />;

const SEARCH_ALT = <BsSearch stroke={0} />;

const SCREENSHOT = <IoCameraReverseOutline />

const COMPUTER = <IoDesktop />;

const DOWNLOAD = <BsDownload stroke={0} style={{ fill: 'var(--text-color-black)' }} />;

const HASHTAG = <BsHash stroke={0} style={{ fill: 'var(--text-color-black)' }}/>

let iconArray = {
    ADD_CIRCLE,
    ADD_FILE,
    GRID,
    GRID_CHECK,
    ATTACH,
    CHECKED,
    CLOSE,
    COLLAPSE,
    COMPUTER,
    DEFAULT,
    DELETE,
    DROP,
    EDIT,
    EXPAND,
    FILE,
    FOLDER,
    HOME,
    IMAGE,
    LINK,
    LINK_OUT,
    LOGOUT,
    LOADING,
    MINIMIZE,
    MOVE,
    NOTE,
    PLUS,
    ADD_ROOT,
    ADD_NODE,
    SAVE,
    SAVED,
    SAVING,
    SETTINGS,
    SIDEBAR,
    SIDEBAR_CLOSE,
    SIDEBAR_OPEN,
    STAR,
    TAG,
    THREE_DOTS,
    TREE,
    UNCHECKED,
    USER,
    VIDEO,
    VIEW,
    LEFTARROW,
    RIGHTARROW,
    DOWNARROW,
    UPARROW,
    SEARCH,
    SEARCH_ALT,
    SCREENSHOT,
    DOWNLOAD,
    UPLOAD,
    ADD_ITEM,
    HASHTAG
}

let Icons = {};

for (let key of Object.keys(iconArray)){
    Icons[key] = (props) => {
        return (
            <IconWrap
                classes={props.classes}
            >
                {iconArray[key]}
            </IconWrap>
        )
    }
}

export default Icons;


// export {
//     ADD_FILE,
//     GRID,
//     ATTACH,
//     CHECKED,
//     CLOSE,
//     COLLAPSE,
//     DEFAULT,
//     DELETE,
//     DROP,
//     EDIT,
//     EXPAND,
//     FILE,
//     FOLDER,
//     HOME,
//     IMAGE,
//     LINK,
//     LINK_OUT,
//     LOGOUT,
//     LOADING,
//     MINIMIZE,
//     MOVE,
//     NOTE,
//     PLUS,
//     SAVE,
//     SAVED,
//     SAVING,
//     SIDEBAR,
//     SIDEBAR_CLOSE,
//     SIDEBAR_OPEN,
//     STAR,
//     STAR_FILLED,
//     TAG,
//     THREE_DOTS,
//     TREE,
//     UNCHECKED,
//     VIDEO,
//     VIEW
// };