import React from 'react';

import classes from './Icons.module.css';

const IconWrap = (props) =>{
    const styles = props.classes? [classes.Icon, ...props.classes] : [classes.Icon];

    return(
        React.cloneElement(props.children, {className: styles.join(' ')})
    )
    
};

export default IconWrap;