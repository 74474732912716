import React from 'react';
import Panel from '../Panel/Panel';
import classes from './Highlights.module.css';
import DetailViewJPEG from '../assets/DetailView.jpeg';
import DetailViewWEBP from '../assets/DetailView.webp';
import DetailViewJPEG600 from '../assets/Mobile/DetailView.jpeg'
import DetailViewWEBP600 from '../assets/Mobile/DetailView.webp'


const View = () => {
    return (
        <section
            className={[classes.Section, classes.View].join(' ')}
        >
            <div className={classes.ImageContainer}>
                
                <picture>
                    <source srcSet={`${DetailViewWEBP600} 600w, ${DetailViewWEBP}`} type={'image/webp'} />
                    <source srcSet={`${DetailViewJPEG600} 600w, ${DetailViewJPEG}`} type={'image/jpeg'} />
                    <img 
                        width={'1626px'} 
                        height={'1049px'} 
                        src={DetailViewJPEG} 
                        className={classes.Image} 
                        alt={'Notes, Files, and Bookmarks Connected'}
                    />
                </picture>
            </div>
            <Panel
                title={'Ideas At A Glance'}
                panelClasses={[classes.Panel, classes.ViewPanel]}
            >
                <p>Take notes and store files alongside your bookmarks. Get a complete view of your ideas in one place.</p>
            </Panel>
        </section>
    )
};

export default View;