import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './rootReducer'
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancer = process.env.NODE_ENV === 'development'? composeWithDevTools(
    { trace: true, traceLimit: 15 }
) : compose;

export const history = createBrowserHistory();

const configureStore = (preloadedState) => {

    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk
                // ... other middlewares ...
            ),
        ),
    )

    return store
}

export default configureStore;