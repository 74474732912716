
import React, { useRef, useState } from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import Confirm from '../../../components/UI/ConfirmDialogs/ConfirmDialog';
import FileInput from '../ItemForm/FileChange/FileInput/FileInput';
import classes from './ImportBookmarksForm.module.css';

const ImportBookmarksForm = (props) =>{

    const formClasses = props.classes? [...props.classes, classes.Form] : [classes.Form];
    const confirmClasses = props.confirmClasses? [...props.confirmClasses, classes.Confirm] : [classes.Confirm];

    const ref = useRef(null);
    const [currentFile, setCurrentFile] = useState(null);

    const bookmarkFileTypes = ['text/html'];

    const validFile = (file) => {
        return bookmarkFileTypes.includes(file.type);
    }

    const changeHandler = () => {
        if (ref.current.files.length) {
            let file = ref.current.files[0];

            if (validFile(file)) {
                setCurrentFile(file);
                props.clicked(true);
            }else{ 
                setCurrentFile(null);
                props.clicked(false);
            }
        }
    }

    const submitHandler = (e) => {
        if(!currentFile) return;

        let formData = new FormData();

        formData.append('import', currentFile);

        props.onSubmit(formData);
        setCurrentFile(null);
        props.clicked(false);
    }

    const confirmTitle = [
        'Import all bookmarks from ',
        <span className={classes.FileTitle} key={'confirmTitle'}>{currentFile?.name}?</span>,
        
    ]

    const confirmation = currentFile? 
        <Confirm
            dialog={confirmTitle}
            dialogClasses={[classes.ConfirmTitle]}
            confirmText={'Import'}
            confirm={submitHandler}
            cancelText={'Cancel'}
            cancel={() => {
                setCurrentFile(null)
                props.clicked(false);
            }}
            containerClasses={confirmClasses}
        />
    : null;

    return (
        <div
            className={formClasses.join(' ')}
        >
            {props.pending? 
                props.children
                :
                [
                    <FileInput
                        id={`ImportBookmarks${props.id}`}
                        ref={ref}
                        accept={bookmarkFileTypes}
                        changeHandler={changeHandler}
                        key={'fileInput'}
                    >
                        {props.children}
                    </FileInput>,
                    confirmation
                ]
            }
        </div>
    )
};

const mapDispatchToProps = (dispatch) => {
    return{
        onSubmit: (formData) => {dispatch(actions.importBookmarks(formData))}
    }
}

export default connect(null, mapDispatchToProps)(ImportBookmarksForm);