import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import * as actionTypes from '../actions/actionTypes';
import * as stateChange from '../utilities/itemUtilities';
import {initDBState} from '../initStateModels';

const initErrorState = {
    active: false,
    type: null
};

const initialState = {
    loading: true,
    pendingAction: false,
    importPending: false,
    items: {},
    deletedItems: {},
    roots: [],
    tags: {},
    error: initErrorState
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_STATE:
            let initState = cloneDeep(initialState);

            for (let item of action.items){
                initState.items[item._id]= {
                    db: {...initDBState},
                    ...item
                }
            }
            for (let deletedItem of action.deletedItems) {
                initState.deletedItems[deletedItem._id] = {
                    db: { ...initDBState },
                    ...deletedItem
                }
            }
            for (let sharedItem of action.sharedItems){
                initState.items[sharedItem._id]= {
                    db: {...initDBState},
                    ...sharedItem
                }
            }

            for (let tag of action.tags){
                initState.tags[tag._id] = tag;
            }

            if (action.roots){ initState.roots = action.roots }

            initState.loading = false;
            initState.pendingAction = false;
            initState.importPending = action.importPending;

            initState.error = initErrorState;

            return initState;
        
        case actionTypes.PENDING_ACTION:
            let pendingActionState = cloneDeep(state);

            pendingActionState.pendingAction = true;

            return pendingActionState;

        case actionTypes.VERIFY_ITEM_STATE:
            let verifiedState = cloneDeep(state);

            const verifyUpdate = (update, updateIndex, allUpdates) => {
                let id = update._id;
                let verifyItem = verifiedState.items[id]? verifiedState.items[id] : verifiedState.deletedItems[id];

                //Verify Deletion

                if (update.deleted){
                    if (verifiedState.items[id]) throw Error(`Current State does not match Server State: ${id}`);
                }


                //Verify Changes
                let changes = Object.keys(update).filter((change) => {
                    return change !== '_id'
                });

                if (changes.length) {

                    let verifiedChanges = changes.every((change) => {
                        return isEqual(verifyItem[change], update[change]);
                    });

                    if (verifiedChanges) {
                        verifyItem.db = {
                            saved: true,
                            submitted: false
                        };
                    } else if (allUpdates.some((update, i) => {
                        return update?._id === id && i > updateIndex
                    })) {
                        
                        let duplicates = allUpdates.filter((update) => {
                            return update?._id === id;
                        });
                        let finalUpdate = duplicates[duplicates.length - 1];
                        let finalIndex = allUpdates.indexOf(finalUpdate);
                        
                        verifyUpdate(finalUpdate, finalIndex, allUpdates);

                    } else{
                        for (let change of changes){
                            verifyItem[change] = update[change];
                        }

                        verifyItem.db = {...initDBState};
                    }
                }
            }

            for (let [updateIndex, update] of action.updates.entries()){
                if (!update) continue;
                verifyUpdate(update, updateIndex, action.updates);
            }

            verifiedState.pendingAction = false;

            return verifiedState;

        case actionTypes.UPDATE_TAGS:

            let updateTagState = cloneDeep(state);
            
            stateChange.updateTags(updateTagState, action.tags);

            return updateTagState;


        case actionTypes.NEW_ITEM:

            let newState = cloneDeep(state);

            newState.pendingAction=false;

            action.item.db = initDBState;
            
            newState.items[action.item._id]= action.item;

            stateChange.addChildren(newState, action.item.parent, [action.item._id]);

            stateChange.updateTags(newState, action.tags);

            if (!action.item.parent) stateChange.setRoots(newState);

            // stateChange.updateTags(newState, action.item);

            return newState;

        case actionTypes.EDIT_ITEM:

            let editState = cloneDeep(state);

            action.item.db = initDBState;

            if (action.item.deleted){
                editState.deletedItems[action.item._id] = action.item;
            } else{
                editState.items[action.item._id] = action.item;
            }

            
            // const updates = Object.keys(action.item).filter((key) => {
            //     return key !== '_id';
            // });

            // const isUpdateValid = updates.every((update) => {
            //     return allowedUpdates.includes(update);
            // });

            // if (!isUpdateValid) throw new Error('Invalid Update');

            // updates.forEach((change) => {
            //     editState.items[action.item._id][change] = action.item[change];
            // });

            stateChange.updateTags(editState, action.tags);

            // stateChange.updateTags(editState, action.item);

            editState.pendingAction = false;

            return editState;

        case actionTypes.DELETE_ITEM:

            let singleDeleteState = cloneDeep(state);

            let deleteItem = singleDeleteState.items[action.item._id];
            
            //Update Parent's Children
            stateChange.updateChildrenArray(singleDeleteState, deleteItem.parent, deleteItem.children, [deleteItem._id]);

            //Update Ancestry
            stateChange.updateChildrenAncestry(singleDeleteState, deleteItem.parent, deleteItem.children);

            deleteItem.deleted = true;
            deleteItem.parent = null;
            deleteItem.ancestors = [];
            deleteItem.children = [];

            singleDeleteState.deletedItems[deleteItem._id] = cloneDeep(deleteItem);

            delete singleDeleteState.items[deleteItem._id];

            if (!deleteItem.parent) stateChange.setRoots(singleDeleteState);

            return singleDeleteState;
        
        case actionTypes.DELETE_BRANCH:

            let branchDeleteState = cloneDeep(state);

            let branchStartItem = branchDeleteState.items[action.item._id];

            stateChange.removeChild(branchDeleteState, branchStartItem.parent, branchStartItem._id);

            stateChange.deleteBranch(branchDeleteState, branchStartItem._id);

            if (!branchStartItem.parent) stateChange.setRoots(branchDeleteState);

            branchStartItem.deleted = true;
            branchStartItem.parent = null;
            branchStartItem.children = [];
            branchStartItem.ancestors = [];

            branchDeleteState.deletedItems[action.item._id] = cloneDeep(branchStartItem);

            delete branchDeleteState.items[action.item._id];

            return branchDeleteState;

        case actionTypes.MOVE_ITEM:{

            if (action.newParent && state.items[action.newParent].ancestors.includes(action._id)) return state;

            let moveSingleState = cloneDeep(state);

            let movedItem = moveSingleState.items[action._id];

            //If Moving a Deleted Item
            if(!movedItem){
                movedItem = cloneDeep(moveSingleState.deletedItems[action._id]);
            }

            if (!movedItem){
                return state;
            }

            let originalChildren = [...movedItem.children];

            let originalParent = movedItem.parent;

            let newParent = moveSingleState.items[action.newParent];
            
            //Update Item's Parent & Ancestors
            if (newParent){
                movedItem.parent = newParent._id;
                movedItem.deleted = newParent.deleted;
                movedItem.ancestors = [...newParent.ancestors, newParent._id];
            }
            //Set Item as Root
            else {
                movedItem.deleted = false;
                movedItem.parent = null;
                movedItem.ancestors = [];
            }

            movedItem.children = [];

            //Switch between state.items & state.deletedItems depending on item.deleted
            if (movedItem.deleted){
                delete moveSingleState.items[action._id];
                moveSingleState.deletedItems[movedItem._id] = movedItem;
            }else{
                delete moveSingleState.deletedItems[action._id];
                moveSingleState.items[movedItem._id] = movedItem;
            }


            //Update Original Parent's children
            let ogParent = stateChange.updateChildrenArray(moveSingleState, originalParent, originalChildren, [action._id]);

            //Update Original Children Ancestry
            let ogChildren = stateChange.updateChildrenAncestry(moveSingleState, originalParent, originalChildren);

            //Update New Parent's Children
            let newParentChildren = stateChange.addChildren(moveSingleState, movedItem.parent, [movedItem._id]);

            //Update New Children's Ancestry
            let ancestry = stateChange.updateChildrenAncestry(moveSingleState, movedItem._id);

            let updates = [
                {
                    _id: movedItem._id,
                    parent: movedItem.parent,
                    ancestors: movedItem.ancestors,
                    children: movedItem.children,
                    deleted: movedItem.deleted
                },
                ogParent,
                ...ogChildren,
                newParentChildren,
                ...ancestry
            ];

            for (let update of updates){
                if (!update) continue;
                moveSingleState.items[update._id].db={
                    saved: false,
                    submitted: true
                }
            }

            stateChange.setRoots(moveSingleState);
            
            return moveSingleState;
        }


        case actionTypes.MOVE_BRANCH:{

            if (action.newParent && state.items[action.newParent].ancestors.includes(action._id)) return state;

            let moveBranchState = cloneDeep(state);

            let originalItem = cloneDeep(moveBranchState.items[action._id]);

            let movedBranch = moveBranchState.items[action._id];
            
            if (!movedBranch){
                movedBranch = moveBranchState.deletedItems[action._id];
                originalItem = cloneDeep(moveBranchState.deletedItems[action._id]);
            }

            if (!movedBranch){
                return state;
            }

            let newParent = moveBranchState.items[action.newParent];

            //Update Item's Parent & Ancestors
            if (newParent) {
                movedBranch.parent = newParent._id;
                movedBranch.deleted = newParent.deleted;
            }
            //Set Item as Root
            else {
                movedBranch.deleted = false;
                movedBranch.parent = null;
            }

            //Switch between state.items & state.deletedItems depending on item.deleted
            if (movedBranch.deleted) {
                delete moveBranchState.items[action._id];
                moveBranchState.deletedItems[movedBranch._id] = movedBranch;
            } else {
                delete moveBranchState.deletedItems[action._id];
                moveBranchState.items[movedBranch._id] = movedBranch;
            }

            movedBranch.ancestors = newParent ? [...newParent.ancestors, newParent._id] : null;

            //Update Original Parent's Children
            let removeChild = stateChange.removeChild(moveBranchState, originalItem.parent, originalItem._id);

            //Update New Parent's Children
            let addChildren = stateChange.addChildren(moveBranchState, movedBranch.parent, [movedBranch._id]);

            //Update Branch Ancestry
            let updateAncestry = stateChange.updateChildrenAncestry(moveBranchState, movedBranch._id);

            let updates = [
                {
                _id: movedBranch._id,
                parent: movedBranch.parent,
                ancestors: movedBranch.ancestors
                },
                removeChild,
                addChildren,
                ...updateAncestry
            ];

            for (let update of updates) {
                if (!update) continue;
                moveBranchState.items[update._id].db = {
                    saved: false,
                    submitted: true
                }
            };

            stateChange.setRoots(moveBranchState);

            return moveBranchState;
        }

        case actionTypes.UPDATE_NOTE:
            let noteState = cloneDeep(state);

            let noteItem = noteState.items[action.item._id];

            noteItem.body.note = action.note;

            noteItem.db = {
                saved: false,
                submitted: true
            };

            noteState.items[action.item._id] = noteItem;

            return noteState;

        case actionTypes.FAVORITE_ITEM:
            let favoriteState = cloneDeep(state);

            let favoriteItem = favoriteState.items[action.item._id];

            if (!favoriteItem) favoriteItem = favoriteState.deletedItems[action.item._id];

            if (!favoriteItem) return state;

            favoriteItem.favorite = !favoriteItem.favorite;
            favoriteItem.db = {
                saved: false,
                submitted: true
            }

            return favoriteState;

        case actionTypes.SET_ITEM_ORDER:
            let sortState = cloneDeep(state);

            let sortParent = action.item.parent;

            let sortArray;
            if (sortParent){
                sortArray = sortState.items[action.item.parent].children;
            }else{
                sortArray = sortState.roots;
            }

            sortArray.splice(action.dragIndex, 1);

            sortArray.splice(action.hoverIndex, 0, action.item._id);

            return sortState;


        //THIS SETS ERROR MODAL IN LAYOUT 
        case actionTypes.ITEM_ERROR:
            let errorState = cloneDeep(state);

            errorState.pendingAction = false;
            
            if (action.active){
                errorState.error = {
                    active: true,
                    type: action.modalType,
                    action: action.action,
                    message: action.message
                }
            }
            else {
                errorState.error = initErrorState
            }

            return errorState;

        case actionTypes.IMPLEMENT_UPDATES:
            let multiState = cloneDeep(state);

            //Cycle through updates
            for( let update of action.updates){
                if(!update) continue;
                
                const id = update._id;

                //If the updated includes deletion
                if (update.deleted){
                    let deletedItem = cloneDeep(multiState.items[id]);

                    if (!deletedItem) cloneDeep(multiState.deletedItems[id]);

                    for (let key in update) {
                        deletedItem[key] = update[key];
                    }

                    multiState.deletedItems[id] = deletedItem;

                    delete multiState.items[id];

                    continue;

                }else{
                    let updateItem = multiState.items[id];
                    
                    if (!updateItem){
                        updateItem = multiState.deletedItems[id];
                    }

                    for (let key in update) {
                        updateItem[key] = update[key];
                    }

                    //Switch item between state.items & state.deletedItems depending on item.deleted
                    if (updateItem.deleted){
                        multiState.deletedItems[id] = updateItem;

                        delete multiState.items[id];
                    }else{
                        multiState.items[id] = updateItem;

                        delete multiState.deletedItems[id];
                    }

                }               
            }

            if (action.tags){
                for (let tag of action.tags){
                    multiState.tags[tag._id] = tag;
                }
            }

            if (action.roots){
                multiState.roots = action.roots;
            }

            multiState.pendingAction = false;

            return multiState;

        case actionTypes.DELETE_TAG:
            const deletedTagState = cloneDeep(state);

            stateChange.updateTags(deletedTagState, action.tags);

            for (let update of action.updates){
                deletedTagState.items[update._id].body.tags = update.tags;
            }

            return deletedTagState;

        case actionTypes.PERMANENT_DELETE:

            const permDeleteState = cloneDeep(state);

            permDeleteState.pendingAction = false;

            for (let update of action.updates){
                if (!update || !update._id) continue;

                delete permDeleteState.deletedItems[update._id];
            }

            return permDeleteState;

        case actionTypes.IMPORT_BOOKMARKS:

            const importState = cloneDeep(state);

            importState.importPending = action.importPending;
            importState.pendingAction = false;

            if (action.items){
                for (let item of action.items){
                    if (!item) continue;

                    importState.items[item._id] = {
                        db: {
                            ...initDBState,
                        },
                        ...item
                    };

                }
            }

            if (action.roots){
                importState.roots = action.roots;
            }

            return importState;

        default:
            return state;
    }
}

export default reducer;