import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from '../HomeLayout.module.css'
import ButtonContainer from '../../../components/UI/ButtonContainer/ButtonContainer';
import Icons from '../../../components/UI/Icons/Icons';

const NavButton = (props) =>{
    const buttonClasses = props.classes? [classes.NavButton, ...props.classes] : [classes.NavButton];

    const containerClasses = props.containerClasses ? [classes.NavButtons, ...props.containerClasses] : [classes.NavButtons];

    const loginButton = !props.isAuthenticated && props.user?
        <NavLink
            to='/auth'
            className={buttonClasses.join(' ')}>
            <Icons.USER /> 
            Login
        </NavLink>
        : null;

    const signUpButton = !props.isAuthenticated && !props.user ?
        <NavLink
            to='/auth/signup'
            className={buttonClasses.join(' ')}>
            Get Started
        </NavLink>
        : null;

    const appButton = props.isAuthenticated ?
        <NavLink
            to='/app'
            className={buttonClasses.join(' ')}>
            Open App
        </NavLink>
        : null;

    const buttons =
        <ButtonContainer
            classes={containerClasses}
        >
            {signUpButton}
            {loginButton}
            {appButton}
        </ButtonContainer>;

    return buttons;
};

export default NavButton;