import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import './styles/index.css'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, {history} from './store/config/configureStore';
import ItemReset from './components/Error/ErrorModals/ItemReset';

const ErrorBoundary = Sentry.ErrorBoundary;

Sentry.init({
  dsn: "https://5c758b035145457d9d49f585a0b911f9@o1070970.ingest.sentry.io/6067480",
  tunnel: `${process.env.REACT_APP_API_BASE}/tunnel`,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: ['ResizeObserver loop completed with undelivered notifications.'],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: .1,
});

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ErrorBoundary
            fallback={({resetError}) => {
              return <ItemReset onReset={() => {
                resetError();
              }} />
            }}
            beforeCapture={(scope) => {
              scope.setLevel(Sentry.Severity.Critical);
            }}
          >
            <App/>
          </ErrorBoundary>
        </ConnectedRouter>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
