import React, { useEffect, useRef, useState } from 'react';
import classes from './FirstView.module.css';
import FirstViewJPEG from '../assets/FirstViewDevices.jpeg';
import FirstViewWEBP from '../assets/FirstViewDevices.webp';
import FirstViewJPEG600 from '../assets/Mobile/FirstViewDevices.jpeg'
import FirstViewWEBP600 from '../assets/Mobile/FirstViewDevices.webp'

import TextSlider from './TextSlider/TextSlider';
import ButtonContainer from '../../../components/UI/ButtonContainer/ButtonContainer';
import { NavLink } from 'react-router-dom';

const FirstView = (props) =>{
    const [initWidth, setWidth] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        setWidth(ref.current.offsetWidth + 10);
    }, [])

    const phrases = [
        'Bookmark',
        'Idea',
        'Inspiration',
        'Research'
    ];
  
    return (
        <section
            className={classes.FirstView}
        >
            <picture>
                <source srcSet={`${FirstViewWEBP600} 600w, ${FirstViewWEBP}`} type={'image/webp'}/>
                <source srcSet={`${FirstViewJPEG600} 600w, ${FirstViewJPEG}`} type={'image/jpeg'} />
                <img 
                    width={'1920px'} 
                    height={'1544px'}
                    src={FirstViewJPEG} 
                    className={classes.Image}
                    alt={'threadly on Devices'}
                />
            </picture>
            <div
                className={classes.MainPanel}
                style={{ minWidth: `${initWidth}px` }}
                ref={ref}
            >
                <TextSlider 
                    beforeSlider={'Thread-Based'}
                    phrases={phrases}
                    afterSlider={'Manager'}
                 />
                <ButtonContainer>
                    {props.isAuthenticated || props.user? 
                        <NavLink
                            to='/app'
                            className={classes.Button}>
                            Open App
                        </NavLink>
                        : 
                        <NavLink
                            to='/auth/signup'
                            className={classes.Button}>
                            Get Started
                        </NavLink>
                    }
                </ButtonContainer>
            </div>
        </section>
    )
};

export default FirstView;