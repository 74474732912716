
//ITEM STATE
export const PENDING_ACTION = 'PENDING_ACTION';

export const INIT_STATE = 'INIT_STATE';

export const NEW_ITEM = 'NEW_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';

export const UPDATE_TAGS = 'UPDATE_TAGS'

export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_BRANCH = 'DELETE_BRANCH';

export const MOVE_ITEM = 'MOVE_ITEM';
export const MOVE_BRANCH = 'MOVE_BRANCH';

export const UPDATE_NOTE = 'UPDATE_NOTE';

export const DELETE_FILE = 'DELETE_FILE';

export const SET_ITEM_ORDER = 'SET_ITEM_ORDER';

export const FAVORITE_ITEM = 'FAVORITE_ITEM';

export const ITEM_ERROR = 'ITEM_ERROR';

export const VERIFY_ITEM_STATE = 'VERIFY_ITEM_STATE';
export const IMPLEMENT_UPDATES = 'IMPLEMENT_UPDATES';

export const IMPORT_BOOKMARKS = 'IMPORT_BOOKMARKS';


//Multiple Item Actions

export const SELECT_ITEM = 'SELECT_ITEM';
export const SELECT_ALL = 'SELECT_ALL';
export const CLEAR_SELECTED = 'CLEAR_SELECTED';


export const PERMANENT_DELETE = 'PERMANENT_DELETE';

//Tags

export const EDIT_TAG = 'EDIT_TAG';
export const DELETE_TAG = 'DELETE_TAG';


//VIEW STATE
export const LOADING_VIEW = 'LOADING_VIEW';

export const INIT_VIEW_STATE = 'INIT_VIEW_STATE';

export const ACTIVE_ANCESTRY = 'ACTIVE_ANCESTRY';
export const ANCESTRY_SETTINGS = 'ANCESTRY_SETTINGS';

export const SET_FLOW_VIEW_ROOT = 'SET_FLOW_VIEW_ROOT';
export const SET_SCROLL_VIEW = 'SET_SCROLL_VIEW';
export const SET_SINGLE_ROOT_VIEW = 'SET_SINGLE_ROOT_VIEW';


export const SET_DETAIL_ITEM = 'SET_DETAIL_ITEM';
export const DETAIL_TIME_TRAVEL = 'DETAIL_TIME_TRAVEL';

export const SET_SELECT_VIEW = 'SET_SELECT_VIEW';
export const SET_SEARCH_VIEW = 'SET_SEARCH_VIEW';
export const SET_TRASH_VIEW = 'SET_TRASH_VIEW';

export const SET_MODAL = 'SET_MODAL';

export const SET_GLIMPSE_VIEW = 'SET_GLIMPSE_VIEW';

export const SET_ITEM_FORM = 'SET_ITEM_FORM';

export const RESET_VIEWS = 'RESET_VIEWS';


//AUTH STATE
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT = 'SET_AUTH_REDIRECT';