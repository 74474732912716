import React from 'react';

import classes from './Button.module.css';

const Button = (props) => {

    const styles = props.classes ? [classes.Button, classes[props.btnType], ...props.classes] : [classes.Button, classes[props.btnType]];

    return(
    <button
        disabled={props.disabled}
        className={styles.join(' ')}
        onClick={(e) => {
            if(props.clicked) props.clicked(e);
            e.preventDefault();
            e.stopPropagation();
            }}>{props.children}</button>
    )
};

export default Button;