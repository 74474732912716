// CHANGES IN THIS FILE NEED TO BE DUPLICATED IN SERVER FILE

const errorTypes = {
    ///AUTH ERRORS
    LOGIN_FAIL: 'Login Unsuccessful. Please check your email/password and try again.',
    SIGNUP_FAIL: 'Sign Up Unsuccessful',
    DUPLICATE_EMAIL: 'An account with that email already exists.',
    VERIFICATION_FAIL: 'Verification Unsuccessful',
    INVALID_TOKEN: 'Authentication Required',

    //USER ERRORS
    USER_NOT_FOUND: 'User Not Found',

    //ITEM ERRORS
    ITEM_NOT_SAVED: 'Item Not Saved',
    ITEM_NOT_DELETED: 'Item Not Deleted',

    ITEM_NOT_FOUND: 'Item Not Found',
    PARENT_NOT_FOUND: 'Parent Not Found',

    INVALID_UPDATE: 'Invalid Update',
    FAVORITE: 'Favorite Unsuccessful',

    //TAG ERRORS
    TAG_NOT_FOUND: 'Tag Not Found',

    //ICON ERRORS
    FORMAT_ERROR: 'Icon Format Error',
    ICON_NOT_FOUND: 'Icon Not Found',
    IMAGE_SCRAPE: 'Image Scrape',
    SCREENSHOT: 'Screenshot',

    //VIEW ERROR
    VIEW_FAIL: 'View Fail',
    VIEW_SAVE_FAIL: 'Current View Not Saved',

    //B2 ERRORS
    B2_AUTHORIZATION: 'B2 Authorization Error',
    FILE_UPLOAD: 'File Upload Error',
    FILE_NOT_FOUND: 'File Not Found',
    FILE_DELETE_ERROR: 'Unable to Delete File',

    //MONGO ERRORS
    MONGO_SAVE_ERROR: 'Mongo Save Error',

    //SCRAPE ERRORS
    SCRAPE_FAIL: 'Scrape Unsuccessful',
    VIDEO_THUMBNAIL: 'Video Thumbnail Scrape Unsuccessful',

    //EMAIL ERRORS
    SEND_MAIL_FAIL: 'Unable to Send Email',

    //IMPORT FAIL
    IMPORT_FAIL: 'Bookmark Import Unsuccessful'

}

module.exports = errorTypes;