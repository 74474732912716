import React from 'react';
import classes from './Panel.module.css';

const Panel = (props) =>{
    const panelClasses = props.panelClasses ? [classes.Panel, ...props.panelClasses] : [classes.Panel];

    const titleClasses = props.titleClasses? [classes.Title, ...props.titleClasses] : [classes.Title];

    const descriptionClasses = props.descriptionClasses ? [classes.Description, ...props.descriptionClasses] : [classes.Description];

    return (
        <div
            className={panelClasses.join(' ')}
        >
            <h2 className={titleClasses.join(' ')}>
                {props.title}
            </h2>
            <div
                className={descriptionClasses.join(' ')}
            >
                {props.description? <p>{props.description}</p> : null}
                {props.children}
            </div>

        </div>
    )
};

export default Panel;