import React, { useEffect, useState } from 'react';
import classes from './TextSlider.module.css';

const TextSlider = ({phrases=[], beforeSlider, afterSlider}) =>{
    const [selected, setSelected] = useState(0);
    const [previous, setPrevious] = useState(-1);


    useEffect(() => {
        const timeout = setTimeout(() => {
            if (selected < phrases.length - 1){ 
                setSelected(selected + 1)
            }else setSelected(0);
            setPrevious(selected);
        }, 4000);

        return () => {clearTimeout(timeout)}
    });

    const phrasesSpans = phrases?.map((phrase, index) => {
        let spanClasses = [classes.Span];

        if (index === selected){
            spanClasses.push(classes.Selected)
        } else if (index === previous) {
            spanClasses.push(classes.Previous)
        } else {
            spanClasses.push(classes.Hidden)
        }

        return <span className={spanClasses.join(' ')} key={phrase}>{phrase}</span>
    });

    return (
        <div
            className={classes.Container}
        >
            <h1
                className={classes.Header}
            >{beforeSlider}
            <span
                className={classes.Slider}
            >
                {phrasesSpans}
            </span>
            {afterSlider}</h1>
        </div>
    )
};

export default TextSlider;