import * as actions from '../index';
import errorTypes from './errorTypes';
import ErrorModalTypes from '../../../components/Error/ErrorModalTypes';

const itemErrorHandler = (error, retry, message) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.data.message) {
            case errorTypes.USER_NOT_FOUND:
            case errorTypes.INVALID_TOKEN:
                return actions.itemError(
                        true,
                        ErrorModalTypes.Authentication
                    );

            case errorTypes.ITEM_NOT_FOUND:
                return actions.itemError(
                        true,
                        ErrorModalTypes.Reset
                    );

            //DO NOTHING
            case errorTypes.SCRAPE_FAIL:
            case errorTypes.SCREENSHOT:
                return {type: null};


            //RETRY-ABLE ERRORS
            case errorTypes.FAVORITE:
            case errorTypes.ITEM_NOT_SAVED:

                if (retry){
                    return actions.itemError(
                        true,
                        ErrorModalTypes.Retry,
                        retry,
                        message
                    )
                }else{
                    return actions.itemError(
                        true,
                        ErrorModalTypes.Reset
                    )
                }
            


            //LET THESE ERRORS FALL THROUGH TO RESET    
            case errorTypes.TAG_NOT_FOUND:    
            case errorTypes.FILE_DELETE_ERROR:
            case errorTypes.INVALID_UPDATE:
            case errorTypes.PARENT_NOT_FOUND:
            case errorTypes.ITEM_NOT_DELETED:
            default:
                return actions.itemError(
                        true,
                        ErrorModalTypes.Reset                  
                    );
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return actions.itemError(
                true,
                ErrorModalTypes.Reset
            )
    } else {
        // Something happened in setting up the request that triggered an Error
        return actions.itemError(
                true,
                ErrorModalTypes.Reset
            );
    }
}

export default itemErrorHandler;