import * as actions from '../index';
import errorTypes from './errorTypes';

const authErrorHandler = (dispatch, error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.data.message) {
            case errorTypes.INVALID_TOKEN:
                return;
            case errorTypes.LOGIN_FAIL:
            case errorTypes.SIGNUP_FAIL:
            case errorTypes.DUPLICATE_EMAIL:
                dispatch(
                    actions.authFail(
                        error.response.data.message
                    )
                )
               break;

            default:
                let message= 'Something went wrong. Please check your credentials and try again.'

                dispatch(
                    actions.authFail(
                        message
                    )
                )
                break;
        }
        

    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        dispatch(
            actions.authFail('An Error Occurred.')
        )
        
    } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(
            actions.authFail('An Error Occurred.')
        )

    }
}

export default authErrorHandler;