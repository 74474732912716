import React from 'react';
import {connect} from 'react-redux'
import Button from '../../../components/UI/Button/Button';
import * as actions from '../../../store/actions/index';
import ImportBookmarksForm from '../ImportBookmarksForm/ImportBookmarksForm';
import Loader from '../../../components/Item/ItemSubcomponents/Icon/Loader';
import Icons from '../../../components/UI/Icons/Icons';
import classes from './EmptyView.module.css';
import ButtonContainer from '../../../components/UI/ButtonContainer/ButtonContainer';
import ButtonDiv from '../../../components/UI/Button/ButtonDiv';
import Grow from '../../../components/UI/Illustrations/Grow';

const EmptyView = (props) =>{

    return (
        <div
            className={classes.Container}
        >
            <Grow classes={[classes.Image]}/>
            <ButtonContainer
                classes={[classes.Actions]}
            >
                <Button
                    clicked={() => {props.onNewRoot()}}
                    classes={[classes.Button]}
                >
                    <Icons.PLUS />
                    Create a Thread
                </Button>
                <ImportBookmarksForm
                    key={'empty'}
                    id={'Placeholder'}
                    clicked={() => {}}
                    confirmClasses={[classes.Confirm]}
                    pending={props.importPending}
                >
                    <ButtonDiv 
                        className={classes.Button}
                    >
                    {props.importPending ?
                        [
                            <Loader classes={[classes.Loader]} key={'emptyViewLoader'}/> ,
                            'Importing Bookmarks'
                        ]
                        :
                        [
                            <Icons.SAVE key={'emptyViewSave'}/>,
                            'Import Bookmarks'
                        ]
                    }
                    </ButtonDiv>
                </ImportBookmarksForm>
            </ButtonContainer>
            </div>
            
    )
};


const mapStateToProps = (state) => {
    return {
        importPending: state.itemState.importPending
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        onNewRoot: () => {dispatch(actions.setItemForm(true, null, null))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyView);