import React from 'react';
import Panel from '../Panel/Panel';
import classes from './Highlights.module.css';
import Secure from '../assets/Secure.png';

const Final = () => {
    return (
        <section
            className={[classes.Section, classes.Final].join(' ')}
        >
            <div className={classes.ImageContainer}>
                <img
                    width={'827px'}
                    height={'647px'}
                    src={Secure}
                    className={classes.Image}
                    alt={'Bookmarks, files, notes secured.'}
                />
            </div>
            <Panel
                title={'Private. Secure. Free.'}
                panelClasses={[classes.Panel, classes.FinalPanel]}
            >
                <p>No trackers, no selling your data. All your bookmarks, files, and notes are kept private to you. They're your ideas, we're just keeping the thread intact.</p>
            </Panel>
        </section>
    )
};

export default Final;