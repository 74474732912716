import React from 'react';
import classes from './HomeLayout.module.css';

const Footer = (props) =>{
  return (
    <footer className={classes.Footer}>
      <p className={[classes.Attribution].join(' ')}>Created with &#9829; by <a className={classes.Email} href={'mailto:chasen@threadly.chasenstark.com'}>Chasen Stark</a></p>
      <p className={[classes.Copyright, classes.Faded].join(' ')}>&#169; threadly {new Date().getFullYear()}</p>
    </footer>
  )
};

export default Footer;