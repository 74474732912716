const updateChildren = (state, parentID, children, update) => {
  let parent = state.items[parentID];
  let childrenArray = children? children : parent.children;
  let updated = [];

  if (!childrenArray || !childrenArray.length) return updated;

  for (let childID of childrenArray) {

    let child = state.items[childID];

    //Preserve children before updating item --> Necessary for recursive deletion
    let childChildrenArray = [...child.children];

    //This has to come before children updates to recursively update ancestry
    let updatedValues = update(child, parent);

    if (childChildrenArray && childChildrenArray.length) {
      let deepUpdates = updateChildren(state, childID, childChildrenArray, update);

      updated = [...updated, ...deepUpdates];
    }

    let change = { _id: childID, ...updatedValues };

    updated.push(change);
  }

  return updated;
}


const updateChildrenAncestry = (state, parentID, children) => {

  //Children updated to root items
  if (!parentID) {
    let completeUpdate = [];
    for (let childID of children) {

      let updatedChild = state.items[childID];
      updatedChild.parent = null;
      updatedChild.ancestors = [];
      updatedChild.deleted = false;

      completeUpdate.push({
        _id: updatedChild._id,
        parent: updatedChild.parent,
        ancestors: updatedChild.ancestors
      });


      let updates = updateChildrenAncestry(state, updatedChild._id);
      completeUpdate = [...completeUpdate, ...updates];

    }
    return completeUpdate;
  }


  let updates = updateChildren(state, parentID, children, (child, parent) => {
    let parentAncestors = parent.ancestors ? parent.ancestors : [];
    child.ancestors = [...parentAncestors, parent._id];
    child.parent = parent._id;
    child.deleted = parent.deleted;

    if (child.deleted) {
      delete state.items[child._id];
      state.deletedItems[child._id] = child;
    } else {
      delete state.deletedItems[child._id];
      state.items[child._id] = child;
    }
    
    return {
      parent: child.parent, 
      ancestors: child.ancestors,
      deleted: child.deleted
    };
  });

  return updates;
  
}

const deleteBranch = (state, parentID) => {
  let updates =  updateChildren(state, parentID, null, (child) => {    
    child.deleted=true;
    child.ancestors = [];
    child.children = [];
    child.parent = null;
    
    return child;
  });

  for (let update of updates){
    state.deletedItems[update._id] = {...update};
    delete state.items[update._id];
  }

  return updates;
}


const addChildren = (state, parentID, childrenIDs) => {
  if (!parentID) return null;
  for (let child of childrenIDs){
    state.items[parentID].children.push(child);
  }
  return { _id: parentID, children: state.items[parentID].children};
}

const removeChild = (state, parentID, childID) => {
  if (!parentID) return null;
  state.items[parentID].children = state.items[parentID].children.filter((id) => {
    return id.toString() !== childID.toString();
  });

  return {
    _id: parentID,
    children: state.items[parentID].children
  }
}

const updateChildrenArray = (state, parentID, toAdd = [], toDelete = []) => {
  let parent = state.items[parentID];

  if (!parent) return null;

  for (let child of toAdd) {
    parent.children.push(child);
  }

  for (let child of toDelete) {
    parent.children = parent.children.filter((id) => {
      return id.toString() !== child.toString();
    });
  }

  return {
    _id: parentID,
    children: parent.children
  };

}

const setRoots = (state) => {
  let noParents = Object.values(state.items).filter((item) => {
    return !item.parent
  }).map((item) => {
    return item._id;
  });

  let roots = state.roots.filter((root) => {
    return noParents.includes(root);
  });

  noParents.forEach((root) => {
    if(!roots.includes(root)) roots.push(root);
    else return;
  });

  state.roots = roots;

  return roots;
}

const updateTags = (state, tags) => {
  if (!tags || !tags?.length) return;
  let newTags = {};
  for (let tag of tags) {
    newTags[tag._id] = tag;
  }

  state.tags = newTags;
}

export {
  updateChildrenAncestry,
  deleteBranch,
  addChildren, 
  removeChild,
  updateChildrenArray,
  setRoots,
  updateTags
};