import React from 'react';
import classes from './ButtonContainer.module.css';

const ButtonContainer = (props) =>{
    const containerClasses = props.classes? [classes.Container, ...props.classes] : [classes.Container];

    return ( 
        <div className={containerClasses.join(' ')}>
            {props.children}
        </div>
    )
};

export default ButtonContainer;