import React from 'react';
import GrowImage from './assets/Growing.svg'
import IllustrationWrapper from './IllustrationWrapper/IllustrationWrapper';

const Grow = (props) => {
    return (
        <IllustrationWrapper
            image={GrowImage}
            height={'651px'}
            width={'752px'}
            {...props}
        />
    )
};

export default Grow;