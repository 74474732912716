import React from 'react';
import { FiLoader } from 'react-icons/fi';

import styles from '../../Item.module.css';

const Loader = (props) =>{
    const classes = props.classes? [styles.Loader, ...props.classes] : [styles.Loader];

    return <FiLoader className={classes.join(' ')} />
};

export default Loader;