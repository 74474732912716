import { cloneDeep, merge } from "lodash"
import createDeepEqualSelector from "../utils/createDeepEqualSelector"
import { parseFilterQuery, parsePageName, parseQuery } from "../utils/queryString"

const itemsSelector = createDeepEqualSelector(
    (state) => { return state.itemState.items},
    (items) => { return items}
)

const glimpseViewSelector = createDeepEqualSelector(
    (state) => { return state.viewState.glimpseView},
    (glimpseView) => {return glimpseView}
)

const rootSelector = createDeepEqualSelector(
    (state) => { return state.viewState.flowView.roots },
    (roots) => { return roots }
)

const selectedItemsSelector = createDeepEqualSelector(
    (state) => { return state.selectState.items},
    (selected) => {return selected}
)

const deletedItemsSelector = createDeepEqualSelector(
    (state) => {return state.itemState.deletedItems},
    (deletedItems) => {return deletedItems}
)

const allItemsSelector = createDeepEqualSelector(
    (state) => { return state.itemState.items },
    (state) => { return state.itemState.deletedItems },
    (items, deletedItems) => {
        const allItems = merge(cloneDeep(items), cloneDeep(deletedItems));

        return allItems;
    }
)


const activeRootsSelector = createDeepEqualSelector(
    (state) => {
        // if (parsePageName(state.router.location.pathname)) return [];

        let {items} = parseQuery(state.router.location.search);

        if (!Array.isArray(items)) return [];

        return items;
    },
    (roots) => {return roots}
)

const trashSelector = createDeepEqualSelector(
    (state) => {
        const page = parsePageName(state.router.location.pathname);
        if (page === 'trash') return true;
        else return false
    },
    (trashState) => {return trashState}
);

const filterSelector = createDeepEqualSelector(
    (state) => {return parseFilterQuery(state.router.location.search)},
    (filters) => {return filters}
);

const activeTags = createDeepEqualSelector(
    (state) => {return filterSelector(state)},
    (filters) => {
        const activeTags = filters
            .filter((obj) => {
                return obj['body.tags'];
            })
            .map((obj) => {
                return obj['body.tags']
            });
        return activeTags;
    }
)

const routerSelector = createDeepEqualSelector(
    (state) => {return state.router.location},
    (router) => {return router}
)

const activeId = createDeepEqualSelector(
    (state) => {
        const query = parseQuery(state.router.location.hash);
        return query.active;
    },
    (active) => {return active}
)

const ancestorsSelector = createDeepEqualSelector(
    (state) => {return activeId(state)},
    (state) => {return state.itemState.items},
    (id, items) => {
        if (!id) return null;

        const item = items[id];

        if (!item) return null;
        
        const ancestors = item.ancestors;

        return ancestors;
    }
)

const activeParentSelector = createDeepEqualSelector(
    (state) => {return activeId(state)},
    (state) => {return state.itemState.items},
    (id, items) => {return items[id]?.parent}
)

const searchSelector = createDeepEqualSelector(
    (state) => {return state.router.location},
    (location) => {
        if (parsePageName(location.pathname).includes('search')){
            let {q} = parseQuery(location.search);

            return q;
        }else return false;
    }
)

const tagSelector = createDeepEqualSelector(
    (state) => { return state.itemState.tags },
    (_, props) => { return props.item},
    (tags, item) => {
        if (!item) return null;
        let fullTags = [];

        for (let tagId of item.body.tags) {
            let fullTag = tags[tagId];
            if (fullTag) fullTags.push(fullTag);
        }

        return fullTags;
    }
)

const selectors = {
    activeRootsSelector,
    trashSelector,
    filterSelector,
    glimpseViewSelector,
    rootSelector,
    selectedItemsSelector,
    routerSelector,
    ancestorsSelector,
    activeParentSelector,
    activeId,
    itemsSelector,
    deletedItemsSelector,
    allItemsSelector,
    searchSelector,
    activeTags,
    tagSelector
}


export default selectors;